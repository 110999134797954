(function ($) {

	$(window).scroll(function () {
		if ($(this).scrollTop() > 800) {
			$('#toTop').fadeIn();
		} else {
			$('#toTop').fadeOut();
		}

		if ($(this).scrollTop() > 110) {
			$('.js-header-block').addClass('header-block--fix');
		} else {
			$('.js-header-block').removeClass('header-block--fix');
		}

	}); 

	$('.open-popup-link').magnificPopup({
		type: 'inline',
		midClick: true
	});

	$('.iframe-popup').magnificPopup({
		type: 'iframe'
	});

	$('.empty-popup').magnificPopup({
		items: {
			src: '<div class="white-popup center"><h3>Просим прощение, но данные еще не загружены.<br>Загляните чуть позже =)</h3></div>',
			type: 'inline'
		}
	});

	$('.image-popup').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		image: {
			verticalFit: true
		}
	});


	$('#toTop').click(function () {
		$('body,html').animate({ scrollTop: 0 }, 800);
	});


	$('.js-slider-news').owlCarousel({
		loop: true,
		margin: 10,
		nav: false,
		dots: false,
		autoplay: true,
		autoplayTimeout: 5000,
		responsive: {
			0: {
				items: 1
			},
			600: {
				items: 2
			},
			1000: {
				items: 4
			}
		}
	});

	// Go to the next item
	$('.js-slider-next').click(function () {
		$('.js-slider-news').owlCarousel().trigger('next.owl.carousel');
	});
	// Go to the previous item
	$('.js-slider-prev').click(function () {
		$('.js-slider-news').owlCarousel().trigger('prev.owl.carousel', [300]);
	});
	$('#mobileMenu').on('click', function () {
		$('.menu-main-menu-container').addClass('menu-main-menu-container-active');
		$('.mobile-menu-bg').addClass('mobile-menu-bg-active');
		$('body').addClass('body-fix');
	});
	$('.mobile-menu-bg').on('click', function () {
		$('.menu-main-menu-container').removeClass('menu-main-menu-container-active');
		$('.mobile-menu-bg').removeClass('mobile-menu-bg-active');
		$('body').removeClass('body-fix');
	});

	var headerSearch = (function () {
		return { 
			vars: {
				btn: '.js-header-search-btn',
				form: '.header-search',
			},

			init: function () {
				$(this.vars.btn).on('click', function () { headerSearch.action(); });
			},
			
			action: function () {
				$(this.vars.form).toggleClass('header-search-open');
				if ($(this.vars.form).hasClass('header-search-open')) {
					$(this.vars.btn).html('<i class="fa fa-times" aria-hidden="true"></i>');
				} else {
					$(this.vars.btn).html('<i class="fa fa-search" aria-hidden="true"></i>');
				}

			}
		};
	}());

	headerSearch.init();

	function calcUi() {
		$( "#slider-range-min-1" ).slider({
			range: "min",
			value: 1.60,
			min: 1.25,
			max: 2.30,
			step: 0.01,
			slide: function( event, ui ) {
			  $( "#n1" ).val(ui.value);
			}
		  });
		 $( "#n1" ).val( $( "#slider-range-min-1" ).slider( "value" ) );
	  
	  
		  $( "#slider-range-min-2" ).slider({
			range: "min",
			value: 55,
			min: 45,
			max: 120,
			slide: function( event, ui ) {
			  $( "#n2" ).val(ui.value);
			}
		  });
		 $( "#n2" ).val( $( "#slider-range-min-2" ).slider( "value" ) );
	}

	function testTabs() {

	}
	
})(jQuery);
